import React from 'react';
import Link from 'next/link';
import ContentImage from '../common/ContentImage.jsx';
import ProductImage from '../common/ProductImage.jsx';
import { checkProductType } from '../../lib/utils/checkProductType.js';

const HomeRevistaComponent = (props) => {
    const homemagzine = props?.magzine?.data;    
    return (
        <div className="col-sm-4">
            <div className="box--wrap">
                <h6 className="novedades">NOVEDADES</h6>
                <h2 className="A-la-venta-esta-sema ">Revista</h2>
                <div className="line5"></div>
                <div className="p-0 my-0 homemagzinesec">
                    { homemagzine?.map((val,i) => (
                        <div key={`magazine${i}`}>
                            <div className="image-block">
                                {/*<Image src={process.env.NEXT_PUBLIC_IMAGE_PATH+`${val.image}`} alt="Revista ECC" layout="fill" loading="eager"></Image>
                                <img src={process.env.NEXT_PUBLIC_IMAGE_PATH+`${val.image}`} alt=""></img>*/}
                                <ProductImage imageName={val.image} slug={`${checkProductType(val.type)}/${val.slug}`} imgTag={true} ></ProductImage>                                
                            </div>
                            <Link href={`${checkProductType(val.type)}/${val.slug}`} passHref={true}>                            
                            <div className="text-block">
                                <h2>{val?.title}  </h2>
                                <div className="magzine-desc" dangerouslySetInnerHTML={{ __html:val.resumen}}></div>
                                <span className="magazin-readMore">Leer más</span>
                            </div>
                            </Link>
                        </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default HomeRevistaComponent;
